import React, { useState, useEffect } from "react";
import Input from "../../components/input";
import { CancelOutlined } from "@mui/icons-material";
import { useQuill } from "react-quilljs";
import useAxiosInstance from "../../hooks/useAxios";
import "quill/dist/quill.snow.css";
import { toast } from "react-toastify";
import { useDebounce } from "use-debounce";

const EmailSender = () => {
  const [toEmails, setToEmails] = useState([
    "rahul.parlani@wokelo.ai",
    "rahulparlani@wokelo.ai",
  ]);
  const [fromEmail, setFromEmail] = useState("");
  const [fromName, setFromName] = useState("");
  const [subject, setSubject] = useState("");
  const [text, setText] = useState("");
  const [html, setHTML] = useState("");
  const [selection, setSelection] = useState("text-editor");
  const [responseData, setResponseData] = useState([]);
  const [loading, setLoading] = useState(false);
  const { quill, quillRef } = useQuill();

  const axios = useAxiosInstance();

  React.useEffect(() => {
    if (quill) {
      quill.on("text-change", (delta, oldDelta, source) => {
        setHTML(
          `${quillRef.current.firstChild.innerHTML}`.replaceAll(
            "<p>",
            "<p style='margin:0px !important; padding:0px !important'>"
          )
        );
      });
    }
  }, [quill]);

  const [
    companyRequestPaginatedPageNumber,
    setcompanyRequestPaginatedPageNumber,
  ] = useState(1);

  const [orgLoadings, setOrgLoading] = useState(false);
  const [isUserListLoading, setIsUserListLoading] = useState(false);
  // `/admin/get-org-users/?&pgsz=20&page=1org_ids=2`
  // `/admin/organisations/?user_id=&start_date=2023-12-15&end_date=2024-07-12&report_type_id=&status=&group=all&org_type=`
  const [org_id_to_details_mappnig, setOrg_id_to_details_mapping] = useState(
    {}
  );
  const [orgs, setOrgs] = useState({});
  const [selectedOrgList, setSelectedOrgList] = useState({});
  const [searchOrg, setSeachOrg] = useState("");
  const [allMessages, setAllMessages] = useState([]);

  //user menu states
  const [selectedUserList, setSelectedUserList] = useState({});
  const [userList, setUserList] = useState({});
  const [
    userPaginatedIntermidatePageNumber,
    setUserPaginatedIntermidatePageNumber,
  ] = useState(1);
  const [userPaginatedPageNumber, setUserPaginatedPageNumber] = useState(1);
  const [userSearch, setUserSearch] = useState("");
  const [userQuery] = useDebounce(userSearch, 1000);

  const getOrgSearchData = (searchOrg, orgs) => {
    if (searchOrg === "") {
      return orgs;
    } else {
      return orgs?.filter((item) =>
        item?.name?.toLowerCase()?.includes(searchOrg?.toLowerCase())
      );
    }
  };

  const getOrgImage = (img, name) => {
    if (img === null) {
      return `https://wklogo.blob.core.windows.net/logos-small/${name[0]?.toUpperCase()}.png`;
    } else {
      return img;
    }
  };

  useEffect(() => {
    setIsUserListLoading(true);
    let new_orgs_ids = Object.keys(selectedOrgList).map((elem) => Number(elem));
    const controller = new AbortController();
    axios
      .get(
        `/admin/get-org-users/?&pgsz=20&page=${userPaginatedPageNumber}&org_ids=${new_orgs_ids}&query=${userQuery}`,
        {
          signal: controller.signal,
        }
      )
      .then((res) => {
        setUserList(res.data);
        setIsUserListLoading(false);
        setUserPaginatedIntermidatePageNumber(res.data.current_page);
      })
      .catch((err) => {
        console.log(err);
        // toast.error("Something went while getting users");
        // setIsUserListLoading(false);
      });

    return () => {
      controller.abort();
    };
  }, [userPaginatedPageNumber, userQuery, selectedOrgList]);

  useEffect(() => {
    setUserPaginatedPageNumber(1);
  }, [userQuery, selectedOrgList]);

  useEffect(() => {
    setcompanyRequestPaginatedPageNumber(1);
  }, [selectedOrgList]);

  useEffect(() => {
    setOrgLoading(true);
    axios
      .get("admin/organisations/")
      .then((res) => {
        setOrgs(res.data);
        let new_mapping = {};
        let all_orgs = res?.data?.organisations;
        all_orgs.forEach((org) => {
          new_mapping[org.org_id] = org;
        });
        setOrgLoading(false);
        setOrg_id_to_details_mapping(new_mapping);
      })
      .catch((err) => {
        console.log(err);
        toast.error("Something went while getting users");
        setOrgLoading(false);
      });
  }, []);

  const [userListForSeletion, setUserListForSeletion] = useState([]);
  const [userListForSeletionLoading, setUserListForSeletionLoading] =
    useState(false);

  useEffect(() => {
    setUserListForSeletionLoading(true);
    const controller = new AbortController();
    axios
      .get(
        `/admin/get-org-users/?pgsz=9999999999999999999999999&page=1&org_type=internal`,
        {
          signal: controller.signal,
        }
      )
      .then((res) => {
        setUserListForSeletion(res.data.users);
        setUserListForSeletionLoading(false);
      })
      .catch((err) => {
        console.log(err);
        // toast.error("Something went while getting users");
        // setIsUserListLoading(false);
      });
  }, []);

  const handlePayloadMaker = () => {
    let message = "";
    if (selection == "text") {
      message = text;
    } else {
      message = html;
    }
    let all_messages = [];
    Object.keys(selectedUserList).forEach((user) => {
      let userData = selectedUserList[user];
      all_messages.push({
        selection: selection,
        to_email: userData.username,
        message: message
          .replaceAll("{{first_name}}", userData.first_name)
          .replaceAll(
            "{{org_name}}",
            org_id_to_details_mappnig[userData.org_id]?.name
          ),
      });
    });
    console.log(all_messages)
    setAllMessages(all_messages);
  };

  return (
    <div>
      <div className="w-[100%] p-6 mt-5 border rounded-lg bg-white">
        <div className="p-3 mt-2 bg-slate-300 w-full rounded-lg border-0">
          <Input
            value={searchOrg}
            onChange={(e) => {
              setSeachOrg(e.target.value);
            }}
            type="text"
            placeholder="Search Orgs"
          />
          <div className="overflow-y-auto h-36 mt-2 bg-slate-100 p-1 rounded-lg w-full">
            <table className="w-full ">
              <thead className="border-b text-left">
                <th className="px-2 py-1 text-gray-700 text-sm ">Select</th>
                <th className="px-2 py-1 text-gray-700 text-sm ">Org Logo</th>
                <th className="px-2 py-1 text-gray-700 text-sm ">Name</th>
                <th className="px-2 py-1 text-gray-700 text-sm ">Label</th>
                <th className="px-2 py-1 text-gray-700 text-sm ">
                  Account Type
                </th>
              </thead>
              <tbody className="divide-y">
                {orgLoadings ? (
                  <div className="flex justify-center flex-col items-center">
                    <p>Loading...</p>
                  </div>
                ) : (
                  getOrgSearchData(searchOrg, orgs?.organisations || []).map(
                    (org) => {
                      return (
                        <tr
                          className="cursor-pointer"
                          onClick={() => {
                            let org_lists = Object.keys(selectedOrgList);
                            let old_org_list = JSON.parse(
                              JSON.stringify(selectedOrgList)
                            );
                            if (org_lists.includes(String(org.org_id))) {
                              delete old_org_list[org.org_id];
                            } else {
                              old_org_list[org.org_id] = org;
                            }
                            setSelectedOrgList(old_org_list);
                          }}
                          key={org.org_id}
                        >
                          <td className="whitespace-nowrap  px-2 py-2 font-medium text-gray-900">
                            <input
                              type="checkbox"
                              readOnly
                              checked={Object.keys(selectedOrgList).includes(
                                String(org?.org_id)
                              )}
                            />
                          </td>
                          <td className="whitespace-nowrap  px-2 py-2 font-medium text-gray-900">
                            {
                              <div
                                style={{
                                  backgroundImage: `url('${getOrgImage(
                                    org?.org_img,
                                    org?.name
                                  )}')`,
                                }}
                                className="h-8 w-8 bg-center bg-no-repeat bg-contain"
                              />
                            }
                          </td>
                          <td className="whitespace-nowrap  px-2 py-2 font-medium text-gray-900">
                            {org?.name}
                          </td>
                          <td className="whitespace-nowrap  px-2 py-2 font-medium text-gray-900">
                            {org?.label}
                          </td>
                          <td className="whitespace-nowrap  px-2 py-2 font-medium text-gray-900">
                            {org?.account_type?.name}
                          </td>
                        </tr>
                      );
                    }
                  )
                )}
              </tbody>
            </table>
          </div>
        </div>

        <div className="p-3 mt-2 bg-slate-300 w-full rounded-lg border-0">
          <Input
            value={userSearch}
            onChange={(e) => {
              setUserSearch(e.target.value);
            }}
            type="text"
            placeholder="Search User"
          />
          <div className="overflow-y-auto h-36 mt-2 bg-slate-100 p-1 rounded-lg w-full">
            <table className="w-full ">
              <thead className="border-b text-left">
                <th className="px-2 py-1 text-gray-700 text-sm ">Select</th>
                <th className="px-2 py-1 text-gray-700 text-sm ">UserName</th>
                <th className="px-2 py-1 text-gray-700 text-sm ">First Name</th>
                <th className="px-2 py-1 text-gray-700 text-sm ">Last Name</th>
                <th className="px-2 py-1 text-gray-700 text-sm ">Org Name</th>
                <th className="px-2 py-1 text-gray-700 text-sm ">Org Type</th>
              </thead>
              <tbody className="divide-y">
                {isUserListLoading ? (
                  <div className="flex justify-center flex-col items-center">
                    <p>Loading...</p>
                  </div>
                ) : (
                  userList?.users?.map((user) => {
                    return (
                      <tr
                        className="cursor-pointer"
                        onClick={() => {
                          let user_lists = Object.keys(selectedUserList);
                          let old_user_list = JSON.parse(
                            JSON.stringify(selectedUserList)
                          );
                          if (user_lists.includes(String(user.user_id))) {
                            delete old_user_list[user.user_id];
                          } else {
                            old_user_list[user.user_id] = user;
                          }
                          setSelectedUserList(old_user_list);
                        }}
                        key={user.user_id}
                      >
                        <td className="whitespace-nowrap  px-2 py-2 font-medium text-gray-900">
                          <input
                            type="checkbox"
                            readOnly
                            checked={Object.keys(selectedUserList).includes(
                              String(user?.user_id)
                            )}
                          />
                        </td>
                        <td className="whitespace-nowrap  px-2 py-2 font-medium text-gray-900">
                          {user.username}
                        </td>
                        <td className="whitespace-nowrap  px-2 py-2 font-medium text-gray-900">
                          {user.first_name}
                        </td>
                        <td className="whitespace-nowrap  px-2 py-2 font-medium text-gray-900">
                          {user.last_name}
                        </td>
                        <td className="whitespace-nowrap  px-2 py-2 font-medium text-gray-900">
                          {org_id_to_details_mappnig[user.org_id]?.name ||
                            "not known"}
                        </td>
                        <td className="whitespace-nowrap  px-2 py-2 font-medium text-gray-900">
                          {org_id_to_details_mappnig[user.org_id]?.account_type
                            ?.name || "not known"}
                        </td>
                      </tr>
                    );
                  })
                )}
              </tbody>
            </table>
          </div>
          <div className="mt-2 flex justify-between items-center">
            <div className="flex space-x-1 items-center">
              {userList.current_page > 1 && (
                <button
                  disabled={isUserListLoading}
                  onClick={() => {
                    setUserPaginatedPageNumber((prev) => prev - 1);
                  }}
                  className="disabled:cursor-not-allowed text-black underline px-0 py-0 rounded-lg"
                >
                  Back
                </button>
              )}

              <div className="p-1 rounded border-black border-2 flex space-x-1 items-center">
                <input
                  value={
                    isUserListLoading
                      ? "loading..."
                      : userPaginatedIntermidatePageNumber
                  }
                  type="number"
                  className="disabled:cursor-not-allowed mx-2 w-10"
                  disabled={isUserListLoading}
                  onChange={(e) => {
                    setUserPaginatedIntermidatePageNumber(
                      Number(e.target.value)
                    );
                  }}
                />
                <button
                  onClick={() => {
                    if (
                      userPaginatedIntermidatePageNumber > 0 &&
                      userPaginatedIntermidatePageNumber <= userList.num_pages
                    ) {
                      setUserPaginatedPageNumber(
                        userPaginatedIntermidatePageNumber
                      );
                    } else {
                      alert("Please enter correct number range");
                    }
                  }}
                  className="bg-black px-1 py-1 text-xs rounded text-white"
                >
                  Apply
                </button>
              </div>
              {userList.current_page < userList.num_pages && (
                <button
                  disabled={isUserListLoading}
                  onClick={() => {
                    setUserPaginatedPageNumber((prev) => prev + 1);
                  }}
                  className="ml-3 disabled:cursor-not-allowed text-black underline px-0 py-0 rounded-lg"
                >
                  Next
                </button>
              )}
            </div>
            <div className="flex space-x-1">
              <div>Total Pages: {userList.num_pages}</div>

              <div>Total Users: {userList.total_users}</div>
            </div>
          </div>
        </div>

        <p className="font-medium text-sm mt-4">Selected Users : </p>
        <div className="p-2 mt-2  bg-slate-300 w-full rounded-lg border-0">
          <div className="h-9 overflow-y-auto bg-white flex flex-wrap space-x-2">
            {Object.keys(selectedUserList).map((user) => (
              <div
                onClick={() => {
                  let userSelected_obj = JSON.parse(
                    JSON.stringify(selectedUserList)
                  );

                  delete userSelected_obj[Number(user)];
                  setSelectedUserList(userSelected_obj);
                }}
                className="px-2 py-1 rounded bg-slate-100"
                key={user}
              >
                {selectedUserList[user]?.username} X
              </div>
            ))}
          </div>
        </div>

        <p className="font-medium text-sm mt-4">Selected Orgs : </p>
        <div className="p-2 mt-2  bg-slate-300 w-full rounded-lg border-0">
          <div className="h-9 overflow-y-auto bg-white flex flex-wrap space-x-2">
            {Object.keys(selectedOrgList).map((org) => (
              <div
                onClick={() => {
                  let userSelected_obj = JSON.parse(
                    JSON.stringify(selectedOrgList)
                  );

                  delete userSelected_obj[Number(org)];
                  setSelectedOrgList(userSelected_obj);
                }}
                className="px-2 py-1 rounded bg-slate-100"
                key={org}
              >
                {selectedOrgList[org]?.name} X
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="w-[100%] p-6 mt-5 border rounded-lg bg-white">
        <p className="text-2xl font-semibold mb-4">Email Sender</p>
        <div className="mt-6">
          <p className="text-sm mb-2 font-medium">from Email</p>
          <Input
            value={fromEmail}
            placeholder = {'hello@wokelo.ai'}
            onChange={(e) => setFromEmail(e.target.value)}
          />

          <p className="text-sm mt-4 mb-2 font-medium">from Name</p>
          <Input
            value={fromName}
            placeholder = {'Wokelo AI'}
            onChange={(e) => setFromName(e.target.value)}
          />

          <p className="text-sm mt-4 mb-2 font-medium">Subject</p>
          <Input value={subject} onChange={(e) => setSubject(e.target.value)} />

          <p className="text-sm mt-4 mb-2 font-medium">to Emails</p>
          <div className="px-2 py-2 flex gap-2">
            {Object.keys(selectedUserList).map((elem) => (
              <div key={elem} className="px-1 py-2 w-max bg-slate-100 rounded">
                {selectedUserList[elem].username}
                <button
                  onClick={() => {
                    let userSelected_obj = JSON.parse(
                      JSON.stringify(selectedUserList)
                    );

                    delete userSelected_obj[Number(elem)];
                    setSelectedUserList(userSelected_obj);
                  }}
                >
                  <CancelOutlined />
                </button>
              </div>
            ))}
          </div>

          <p className="text-sm mt-4 mb-2 font-medium">text</p>
          <select
            className="border rounded-lg px-3 py-2 w-full focus:ring-0 focus:outline-gray-400 disabled:bg-gray-300"
            value={selection}
            onChange={(e) => setSelection(e.target.value)}
          >
            <option value={""}>Choose</option>
            <option value={"text"}>Text</option>
            <option value={"text-editor"}>Text Editor</option>
          </select>

          {selection == "text" ? (
            <>
              <p className="text-sm mt-4 mb-2 font-medium">text</p>
              <Input value={text} onChange={(e) => setText(e.target.value)} />
            </>
          ) : (
            <>
              <p className="text-sm mt-4 mb-2 font-medium">html</p>
              <div className="w-full h-full">
                <div ref={quillRef} />
              </div>
            </>
          )}

          <button
            className="px-2 py-1 mt-4 rounded-lg bg-black text-white"
            onClick={() => {
              let payload = {
                from_email: "hello@wokelo.ai",
                from_name: "Wokelo AI",
                to_emails: ["rahul.parlani@wokelo.ai"],
                subject: "I am testing",
              };
              if (selection == "text") {
                payload["text"] = text;
              } else {
                payload["html"] = html;
              }
              handlePayloadMaker()
              // handleSubmit(payload)
            }}
          >
            Preview
          </button>

          <div>
            <p className="text-xl font-medium">Preview</p>
            <div className="overflow-y-auto max-h-96 mt-2 bg-slate-100 p-1 rounded-lg w-full">
              <table className="w-full ">
                <thead className="border-b text-left">
                  <th className="px-2 py-1 text-gray-700 text-sm ">toEmail</th>
                  <th className="px-2 py-1 text-gray-700 text-sm ">message</th>
                </thead>
                <tbody className="divide-y">
                  {allMessages.map((elem) => {return <tr>
                      <td className="whitespace-nowrap  px-2 py-2 font-medium text-gray-900">
                        {elem.to_email}
                      </td>
                      <td className="whitespace-nowrap  px-2 py-2 font-medium text-gray-900">
                         {elem.selection != 'text'? <div dangerouslySetInnerHTML={{__html:elem.message.toString()}}/> : elem.message }
                      </td>
                    </tr>;
                  })}
                </tbody>
              </table>
            </div>
          </div>

          <button
          disabled={loading}
          onClick={()=>{
            setLoading(true)
            let all_payloads = []
            allMessages.forEach(message=>{
                let payload = {
                    from_email: fromEmail,
                    from_name: fromName,
                    to_emails: [message.to_email],
                    subject: subject,
                  };
                  if (message.selection == "text") {
                    payload["text"] = message.message;
                  } else {
                    payload["html"] = message.message;
                  }
                //   setRes
                  axios.post('/assets/send_custom_mail/',payload).then(s=>{
                    s.data.response.forEach(elemm=>{
                        if(elemm.status == 'sent'){
                            toast.success(`Mail sent to ${elemm.email}`)
                        }else{
                            toast.error(`Mail sent failed to ${elemm.email}`)
                        }
                    })
                  }).catch(err=>{
                    toast.error(`Mail sent failed to ${message.to_email}`)
                  })
            })
          }}
          className="mt-4 disabled:cursor-not-allowed text-base font-medium px-2 py-1 bg-black text-white rounded-lg" 
          >Send Mails</button>
          <button onClick={()=>{setLoading(false)}} className="px-2 ml-1 py-1 bg-black text-white rounded-lg">unlock send email button</button>
        </div>
      </div>
    </div>
  );
};

export default EmailSender;
